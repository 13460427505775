import React from "react";
import { useState } from "react";
import userIcon from "../../assets/user.svg"; // صورة المستخدم
import npcIcon from "../../assets/gpt.svg"; // صورة NPC
import "../../index.css";

const ChatHistory = (props) => {
	const { history, showHistory, messages, npcName, userName } = props;
	const [feedbacks, setFeedbacks] = useState(Array(messages?.length).fill(0));

	return (
		<section>
			<div className={history ? "chat-History" : "chat-Historyc"}>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
					}}>
					{history ? (
						<>
							<p></p>
						</>
					) : (
						""
					)}
				</div>
				{!history ? <></> : <></>}
				<div
					className="container-chat1"
					style={{
						maxWidth: "500px",
						height: "80%",
						overflow: "auto",
						marginBottom: "15px",
						marginTop: "15px",
						marginLeft: "20px",
						textAlign: "left",
						display: "flex",
						flexDirection: "column",
					}}>
					{messages?.map((message, idx) => {
						const isUserMessage = message.sender === "user";
						const nextMessage = messages[idx + 1];
						const isNextMessageUser =
							!nextMessage || nextMessage.sender === "user";
						const isNextMessageNpc =
							!nextMessage || nextMessage.sender === "npc";

						const messageStyle = {
							color: "#FFFFFF",
							padding: "10px",
							marginBottom: "10px",
							display: "flex",
							alignItems: "center",
							gap: "10px",
							borderRadius: "14px",
							border: "1px solid rgba(251, 251, 251, 0.3)",
							backgroundColor: isUserMessage
								? "rgba(0, 0, 0, 0.2)"
								: "rgba(110, 175, 217, 0.1)",
							backdropFilter: "blur(107px)",
						};

						return (
							<section key={idx}>
								{message.sender === "user" && isNextMessageNpc && history
									? message.content && (
											<div style={messageStyle}>
												<img
													src={userIcon}
													alt="User Icon"
													style={{
														width: "40px",
														height: "40px",
														borderRadius: "50%",
													}}
												/>
												<div>
													<span
														style={{
															color: "#fff",
															marginRight: "-5px",
															fontWeight: "bold",
														}}>
														{/* {userName} : */}
													</span>
													<span>{message.content}</span>
												</div>
											</div>
									  )
									: message.sender === "npc" && isNextMessageUser && history
									? message.content && (
											<div style={messageStyle}>
												<img
													src={npcIcon}
													alt="NPC Icon"
													style={{
														width: "40px",
														height: "40px",
														borderRadius: "50%",
													}}
												/>
												<div>
													<span
														style={{
															color: "red",
															marginRight: "-10px",
															fontWeight: "bold",
														}}>
														{/* {npcName} : */}
													</span>
													<span>{message.content}</span>
												</div>
											</div>
									  )
									: ""}
							</section>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default ChatHistory;
