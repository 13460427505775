import React, { useState, useRef } from "react";
import { FaMicrophone } from "react-icons/fa";

const ChatBubblev1 = ({
	userText,
	npcText,
	startRecording,
	stopRecording,
	onSendMessage,
}) => {
	const [isRecording, setIsRecording] = useState(false);
	const formRef = useRef(null);
	const [message, setMessage] = useState("");

	const handleMouseDown = () => {
		setIsRecording(true);
		startRecording();
	};

	const handleMouseUp = () => {
		setIsRecording(false);
		stopRecording();
	};
	const handleInputChange = (e) => {
		setMessage(e.target.value);
	};

	// Handle form submission (sending message)
	const handleSubmit = (e) => {
		e.preventDefault();
		if (message.trim() !== "") {
			onSendMessage(message); // Send message to the AI
			setTimeout(() => {
				setMessage(""); // تفريغ الرسالة بعد فترة قصيرة
			}, 1000);

			// Clear the input field
		}
	};
	const handleKeyDown = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSubmit(e);
		}
	};

	return (
		<div style={{ width: "100%" }}>
			<section className="form_texterea">
				<section
					className="container-chat"
					style={{
						borderRadius: "10px",
						marginBottom: "15px",
						marginTop: "25px",
						// width: "80%",
					}}>
					{userText === "" ? (
						<form
							id="chat-form"
							ref={formRef}
							className="form"
							onSubmit={handleSubmit}>
							<textarea
								className=""
								placeholder="Enter your message"
								value={message} // Bind input field to message state
								onChange={handleInputChange} // Update state when user types
								onKeyDown={handleKeyDown}
							/>
							<button className="button" type="submit">
								<img src="/button.svg" />
							</button>
						</form>
					) : (
						<div>
							<p
								style={{
									color: "#FFFFFF",
									paddingLeft: "20px",
									marginBottom: "-10px",
									marginTop: "10px",
								}}>
								{userText}
							</p>
							<p
								style={{
									color: "#fff",
									paddingLeft: "20px",
									marginTop: "15px",
								}}>
								{/* {npcText} */}
							</p>
						</div>
					)}
					<div
						className="mic_style"
						onMouseDown={handleMouseDown}
						onMouseUp={handleMouseUp}>
						<FaMicrophone size="1.5rem" color="white" />
						{isRecording && (
							<div className="icon">
								<span className="span-prop" />
								<span className="span-prop" />
								<span className="span-prop" />
							</div>
						)}
						<select
							onChange={(e) => {
								const selectedValue = e.target.value;
								if (selectedValue === "https://ar-shj.beyin.ae") {
									window.location.href = selectedValue;
								} else if (selectedValue === "ar-SA") {
									window.location.href = "https://en-shj.beyin.ae"; // رابط النسخة الإنجليزية
								}
							}}
							className="language-select"
							>
							<option value="https://en-shj.beyin.ae">EN</option>
							<option value="https://ar-shj.beyin.ae">AR</option>
						</select>
					</div>
				</section>
			</section>
		</div>
	);
};

export default ChatBubblev1;
