import { useConvaiClient } from "./hooks/useConvaiClient";
import ChatBubble from "./components/chat/Chat";

function App() {
	
	const { client } = useConvaiClient(
		"68688f82-7674-11ef-9274-42010a7be011",
		"2877b9f8ff76c95f8ff2ead333761e82",
	);
	console.log("Convai Client:", client);
	return (
		<div className="box-iframe">
			<iframe
				className="iframe"
				src="https://www.spatial.io/embed/Shjpolice-658bcc2c60c4e4c38b3d866e?share=9004947785570802184"
				width={"100%"}
				height={"100%"}
			/>
			{client ? <ChatBubble chatHistory={"Show"} client={client} /> : null}
		</div>
	);
}

export default App;
